export const environment = {

  production: true,

  protocol: 'https://',
  mwBase: 'mw.sbsdc.nl',
  mwVersion: '/api/v2',

  intakeEndpoint: 'https://mw.sbsdc.nl/api/v2',
  emsAddress: 'https://ems.sustainablebuildings.nl/'

};
