import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: 'login', loadChildren: () => import('app/login/login.module').then(m => m.LoginModule)},
  {path: 'register', loadChildren: () => import('app/register/register.module').then(m => m.RegisterModule)},
  {path: 'intake', loadChildren: () => import('app/intake/intake.module').then(m => m.IntakeModule)},
  {path: 'thanks', loadChildren: () => import('app/thanks/thanks.module').then(m => m.ThanksModule)},
  {path: '', pathMatch: 'full', redirectTo: '/login'}
];

export const AppRouting = RouterModule.forRoot(routes);
